import React from "react";
import gameOver from "./assets/gameOver.png";
const GameOver = ({ setGameOver }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="bg-gray-800 p-5 rounded-3xl shadow-md shadow-green-300/50 text-yellow-200 text-center w-11/12 m-auto">
        <h1 className="font-special text-2xl mb-4">Game Over!</h1>
        <img
          src={gameOver} // Replace with your image path
          alt="Game Over"
          className="mx-auto mb-4 rounded-xl w-3/4" // Adjust styling as needed
        />
        <p className="mb-4">
          Oops! You clicked on the wrong hole. Don't worry, try again and aim
          for a higher score!
        </p>
        <button
          className="bg-yellow-600 text-gray-200 rounded-2xl py-2 px-4 shadow-sm shadow-green-100/50 border-double border-4"
          onClick={() => {
            setGameOver(false);
          }}
        >
          Restart Game
        </button>
      </div>
    </div>
  );
};

export default GameOver;
