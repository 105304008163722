import { initUtils } from "@telegram-apps/sdk-react";
import { useEffect, useState } from "react";
import Nav from "./nav";

export default function Invite({ setPage, data, setData }) {
  const [ref, setRefs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://server.whackhamster.com/getrefs",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: data.id }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setRefs(result);

        console.log(result);

        // Do something with the result here
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
  }, []);

  const utils = initUtils();

  return (
    <>
      <div className="h-screen pt-5 overflow-hidden">
        <div className="flex text-center flex-col justify-center w-11/12 m-auto bg-gray-800 p-5 font-special shadow-md shadow-green-300/50 text-yellow-200 rounded-3xl">
          <h1 className="text-lg font-bold">
            Invite Your Friends and Earn Rewards!
          </h1>
          <h1>
            Spread the word about our exciting game and earn fantastic rewards
            for every friend you invite!
          </h1>
          <div className="flex gap-1 w-full mt-2">
            <div className="bg-yellow-600 text-gray-200 rounded-2xl w-1/2 shadow-sm shadow-green-100/50 border-double border-4">
              <h1>Non-Premium</h1>
              <h1>50k</h1>
            </div>

            <div className="bg-yellow-600 text-gray-200 rounded-2xl w-1/2 shadow-sm shadow-green-100/50 border-double border-4">
              <h1>Premium</h1>
              <h1>100k</h1>
            </div>
          </div>
          <h1 className="mt-2 text-lg font-bold">It's Simple</h1>
          <ul className="list-disc list-inside">
            <li>Share your unique invite link with your friends.</li>
            <li>Watch your rewards grow as they join and start playing.</li>
          </ul>
          <h1>Start inviting now and level up your game!</h1>
          <button
            className="btn btn-accent mt-2"
            onClick={() => {
              utils.shareURL(
                `https://t.me/whackhamster_bot/joinstartapp=${data.id}`,
                `
              🎉 **Unlock Exclusive Rewards!** 🎉

I’ve been playing this awesome game and thought you'd love it too! 🌟
Join now and let’s conquer the game together! 💪🔥
Can’t wait to see you in the game! 🎮🎉
                `
              );
            }}
          >
            Invite
          </button>
          <div className="mt-4">
            <h1>Total Friends Invited {ref.length}</h1>
            <div className="overflow-y-auto max-h-40">
              <table className="table w-full">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {ref.map((res, idx) => (
                    <tr key={res._id} className="friend-item">
                      <td>{idx + 1}</td>
                      <td>{res.firstName}</td>
                      <td>{res.score}</td>
                      {/* Render other properties as needed */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Nav setPage={setPage} />
    </>
  );
}
