export default function Loading() {
  console.log("inside loader");
  return (
    <>
      <div className="flex justify-center align-middle content-center">
        <h1 className="font-3xl">Loading</h1>
      </div>
    </>
  );
}
