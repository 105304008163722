import { FaHome } from "react-icons/fa";
import { PiCoinsFill } from "react-icons/pi";
import { BiLogoTelegram } from "react-icons/bi";
import { SiBoosty } from "react-icons/si";
export default function Nav({ setPage }) {
  return (
    <>
      <div className=" bg-transparent fixed bottom-0.5 flex wrap h-16 justify-between w-full ">
        <div
          className="flex flex-col justify-center content-center items-center w-11/12   bg-gray-800 rounded-t-3xl shadow-md shadow-green-200/50 border-t-2 border-green-200/50 "
          onClick={() => {
            setPage({
              isHome: true,
              isEarn: false,
              isInvite: false,
              isBoost: false,
            });
          }}
        >
          <h1>
            <FaHome className="font-special bottom-0 text-yellow-400 text-2xl text-center z-10" />
          </h1>

          <h1 className=" font-special bottom-0 text-yellow-400 text-2xl text-center z-10">
            Home
          </h1>
        </div>
        <div
          className="flex flex-col justify-center content-center items-center  w-11/12   bg-gray-800 rounded-t-3xl shadow-md shadow-green-200/50 border-t-2 border-green-200/50 "
          onClick={() => {
            setPage({
              isEarn: true,
              isInvite: false,
              isHome: false,
              isBoost: false,
            });
          }}
        >
          <h1>
            <PiCoinsFill className="font-special bottom-0 text-yellow-400 text-2xl text-center z-10" />
          </h1>

          <h1 className=" font-special bottom-0 text-yellow-400 text-2xl text-center z-10">
            Earn
          </h1>
        </div>
        <div
          className="flex flex-col justify-center content-center items-center  w-11/12   bg-gray-800 rounded-t-3xl shadow-md shadow-green-200/50 border-t-2 border-green-200/50"
          onClick={() => {
            setPage({
              isInvite: true,
              isHome: false,
              isEarn: false,
              isBoost: false,
            });
          }}
        >
          <h1>
            <BiLogoTelegram className="font-special bottom-0 text-yellow-400 text-2xl text-center z-10" />
          </h1>
          <h1 className=" bottom-0 font-special text-yellow-400 text-2xl text-center z-10">
            Invite
          </h1>
        </div>
        <div
          className="flex flex-col justify-center content-center items-center w-11/12   bg-gray-800 rounded-t-3xl shadow-md shadow-green-200/50 border-t-2 border-green-200/50"
          onClick={() => {
            setPage({
              isBoost: true,
              isHome: false,
              isInvite: false,
              isEarn: false,
            });
          }}
        >
          <h1>
            <SiBoosty className="font-special bottom-0 text-yellow-400 text-2xl text-center z-10" />
          </h1>
          <h1 className=" font-special bottom-0 text-yellow-400 text-2xl text-center z-10">
            Boost
          </h1>
        </div>
      </div>
    </>
  );
}
