import Nav from "./nav";
import boost from "./assets/boost.png";
import { IoArrowForwardCircle, IoCheckmarkDoneCircle } from "react-icons/io5";
export default function Boost({ setPage, data, setData }) {
  const handleMultipler = async () => {
    const result = await fetch(
      "https://server.whackhamster.com/upgradeMultiplier",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: data.id }),
      }
    );
    if (!result.ok) return;
    const retrievedData = await result.json();
    setData({
      id: retrievedData._id,
      name: retrievedData.firstName,
      username: retrievedData.username,
      score: retrievedData.score,
      tasks: retrievedData.tasks,
      refs: retrievedData.refs,
      multiplier: retrievedData.multiplier,
      multiplierFee: retrievedData.multiplierFee,
    });
  };
  return (
    <>
      <div className="h-screen flex flex-col">
        {/* Fixed Header */}
        <div className="h-16 bg-gray-800 rounded-b-3xl shadow-md shadow-green-200/50 flex justify-center items-center">
          <h1 className="font-special text-yellow-400 text-5xl text-center">
            {data.score}
          </h1>
        </div>
        {/* Section Description */}
        <div className="w-11/12 m-auto mt-3 bg-gray-800 p-3 font-special shadow-md shadow-green-300/50 text-yellow-200 rounded-3xl text-center">
          <h1 className="font-special text-yellow-400 text-3xl text-center">
            Boost Your Scoring Power
          </h1>
          <h1>
            Enhance your gameplay with powerful boosts! Activate the boosts
            below to maximize your score increment. More boosts are coming soon!
          </h1>

          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto p-4">
            <div className="overflow-x-auto">
              <table className="table table-zebra">
                {/* head */}

                <tbody>
                  {/* row 1 */}
                  <tr>
                    <td>
                      <div className="flex items-center gap-3">
                        <div className="avatar">
                          <div className="mask mask-squircle h-12 w-12">
                            <img src={boost} alt="Upgrade Icon" />
                          </div>
                        </div>
                        <div>
                          <div className="font-bold">Score Upgrade</div>
                          <div className="text-sm opacity-50">
                            Level {data.multiplier + 1} For {data.multiplierFee}
                          </div>
                        </div>
                      </div>
                    </td>

                    <th>
                      {data.score >= data.multiplierFee ? (
                        <button onClick={handleMultipler}>
                          <IoArrowForwardCircle className="text-3xl text-green-400" />
                        </button>
                      ) : (
                        <IoCheckmarkDoneCircle className="text-3xl text-green-400" />
                      )}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* Coming Soon Message */}
          <div className="p-4 text-center text-md text-yellow-200">
            More boosts are on the way! Stay tuned for additional ways to power
            up your game.
          </div>
        </div>
      </div>
      <Nav setPage={setPage} />
    </>
  );
}
