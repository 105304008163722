import { useState, useEffect } from "react";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { IoArrowForwardCircle } from "react-icons/io5";
import boost from "./assets/boost.png";
export default function CampaignData({
  campaign,
  tasks,
  userData,
  setUserData,
}) {
  const [campaignTasks, setCampaignTasks] = useState([]);
  const [toast, setToast] = useState({
    isToast: false,
    toastMessage: "",
  });

  const updateTasks = async (id, reward) => {
    try {
      const result = await fetch(
        "https://server.whackhamster.com/updateUserTasks",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ taskId: id, userId: userData.id }),
        }
      );
      //   if (!result.ok) {
      //     throw new Error("Failed to complete task");
      //   }
      setUserData((prevState) => ({
        score: prevState.score + reward,
        tasks: [...prevState.tasks, id],
      }));
    } catch (e) {
      setToast({
        isToast: true,
        toastMessage: e.message,
      });
    }
  };
  useEffect(() => {
    const getCampaignTasks = () => {
      if (!campaign.tasks || !tasks.length) return [];
      console.log(tasks);
      console.log(userData);
      return campaign.tasks
        .map((taskId) => {
          const task = tasks.find((task) => task._id === taskId);
          if (task) {
            return (
              <tr className="bg-base-200 " key={task._id}>
                <td>
                  <img
                    src="https://cdn.pixabay.com/photo/2021/12/27/10/50/telegram-icon-6896828_1280.png"
                    alt=""
                    className="h-8 w-8"
                  />
                </td>
                <td>{task.name}</td>

                <td>{task.reward}</td>
                <td>
                  {userData?.tasks?.includes(task._id) ? (
                    <button className="btn-disabled">
                      <IoCheckmarkDoneCircle className="text-3xl text-green-400" />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setTimeout(
                          () => updateTasks(task._id, task.reward),
                          1000
                        );
                      }}
                    >
                      <a href={task.link}>
                        <IoArrowForwardCircle className="text-3xl text-green-400" />
                      </a>
                    </button>
                  )}
                </td>
              </tr>
            );
          }
          return null;
        })
        .filter((task) => task !== null);
    };

    setCampaignTasks(getCampaignTasks());
  }, [campaign.tasks, tasks, userData]);

  return (
    <>
      <div className="collapse collapse-arrow bg-base-200 mb-1 w-11/12 m-auto font-special text-yellow-600">
        <input type="radio" name="my-accordion-2" />
        <div className="collapse-title text-xl font-medium">
          {campaign.name}
        </div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table table-zebra ">
              <thead></thead>
              <tbody>{campaignTasks}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
