import { useState, useEffect } from "react";

import CampaignData from "./campaigndata";
import Nav from "./nav";
export default function Earn({ setPage, data, setData }) {
  const [campaigns, setCampaigns] = useState([]);
  const [tasks, setTasks] = useState([]);
  const getData = async () => {
    const campaignResult = await fetch(
      "https://server.whackhamster.com/campaigns"
    );
    const campaignData = await campaignResult.json();
    console.log(campaignData);
    setCampaigns(campaignData);
    const taskResult = await fetch("https://server.whackhamster.com/tasks");
    const taskData = await taskResult.json();
    console.log(taskData);
    setTasks(taskData);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="h-screen flex flex-col">
        {/* Fixed Header */}
        <div className="h-16 bg-gray-800 rounded-b-3xl shadow-md shadow-green-200/50 flex justify-center items-center">
          <h1 className="font-special text-yellow-400 text-5xl text-center">
            {data.score}
          </h1>
        </div>
        <div className="flex justify-center w-11/12 m-auto mt-3 bg-gray-800 p-2 font-special shadow-md shadow-green-300/50 text-yellow-200 rounded-full">
          <h1>Earn By Completing The Tasks</h1>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto p-4">
          {campaigns.map((campaign) => (
            <CampaignData
              campaign={campaign}
              tasks={tasks}
              userData={data}
              key={campaign._id}
              setUserData={setData}
            />
          ))}
        </div>
        <Nav setPage={setPage} />
      </div>
    </>
  );
}
