import "./App.css";
import Game from "./game";
import Earn from "./earn";
import Nav from "./nav";
import UserList from "./rank";
import Loading from "./loading";
import logo from "./assets/logo.png";
import {
  retrieveLaunchParams,
  initClosingBehavior,
  // initUtils,
} from "@telegram-apps/sdk";
import { useBackButton } from "@telegram-apps/sdk-react";
import { FaTrophy } from "react-icons/fa";

import { Suspense, useEffect, useState } from "react";
import Invite from "./invite";
import Boost from "./boost";
import StartGame from "./start";
import GameOver from "./gameOver";

function App() {
  const initialValues = {
    id: "",
    name: "",
    username: "",
    tasks: [],
    refs: [],
    multiplier: 0,
    multiplierFee: "",
    score: "",
  };
  const [data, setData] = useState(initialValues);
  const [page, setPage] = useState({
    isHome: true,
    isEarn: false,
    isBoost: false,
    isInvite: false,
  });
  const [start, setStart] = useState(true);
  const [gameOver, setGameOver] = useState(false);
  const [ranks, setRanks] = useState(false);

  const { initDataRaw } = retrieveLaunchParams();

  const [ClosingBehavior] = initClosingBehavior();
  // const utils = initUtils();

  // console.log(initData);
  const fetchData = async () => {
    try {
      if (initDataRaw) {
        const result = await fetch("https://server.whackhamster.com/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `tma ${initDataRaw}`,
          },
        });
        let retrievedData = await result.json();

        setData({
          id: retrievedData._id,
          name: retrievedData.firstName,
          username: retrievedData.username,
          score: retrievedData.score,
          tasks: retrievedData.tasks,
          refs: retrievedData.refs,
          multiplier: retrievedData.multiplier,
          multiplierFee: retrievedData.multiplierFee,
        });
        console.log(retrievedData);
      }
    } catch (e) {
      console.log("error from user module");
    }
  };
  // miniApp.ready();
  useEffect(() => {
    ClosingBehavior.enableConfirmation();
    fetchData();
  }, []);
  const bb = useBackButton(true);

  useEffect(() => {
    if (bb) {
      if (!page.isHome) {
        bb.show();
      }

      bb.on("click", () => {
        setPage({
          isHome: true,
          isEarn: false,
          isInvite: false,
          isBoost: false,
        });
        bb.hide();
      });
    }
  }, [bb, page]);

  return (
    <>
      <Suspense fallback={<Loading />}>
        {start && <StartGame setStart={setStart} />}
        {gameOver && <GameOver setGameOver={setGameOver} />}
        {/* {ranks && <UserList currentUserId={data.id} setRanks={setRanks} />} */}
        {page.isHome && (
          <div className="container">
            <div className="flex text-center flex-col justify-center w-full  font-special m-auto  text-yellow-200 rounded-3xl">
              <div className="h-16 bg-gray-800 rounded-b-3xl  shadow-md shadow-green-200/50 flex justify-center align-middle fixed top-0 p-3 w-full">
                <img
                  src={logo}
                  alt=" coin logo"
                  className="animate-bounce h-10 "
                />
                <h1 className="font-special text-yellow-400 text-5xl text-center">
                  {data.score}
                </h1>
                {/* <div
                  className="fixed top-2 right-2 flex flex-col justify-center cursor-pointer"
                  onClick={() => {
                    setRanks(true);
                  }}
                >
                  <h1 className="text-2xl">
                    <FaTrophy c />
                  </h1>
                  <h1>Rank</h1>
                </div> */}
              </div>
              <Game data={data} setData={setData} setGameOver={setGameOver} />
              <Nav setPage={setPage} />
            </div>
          </div>
        )}
        {page.isEarn && (
          <Earn setPage={setPage} data={data} setData={setData} />
        )}
        {page.isInvite && (
          <Invite setPage={setPage} data={data} setData={setData} />
        )}
        {page.isBoost && (
          <Boost setPage={setPage} data={data} setData={setData} />
        )}
      </Suspense>
    </>
  );
}

export default App;
