import "./App.css";
import mole from "./assets/mole.png";
import hole from "./assets/hole.png";
import React, { useState, useEffect, useRef } from "react";
import { initHapticFeedback } from "@telegram-apps/sdk";

function Game({ data, setData, setGameOver }) {
  const [score, setScore] = useState(0);
  const [speed, setSpeed] = useState(1000);
  const [clicks, setClicks] = useState(0);
  const [perClick, setPerClick] = useState(data.multiplier);

  const hapticFeedback = initHapticFeedback();
  const [showMultiplier, setShowMultiplier] = useState(false);
  const [multiplierPosition, setMultiplierPosition] = useState({ x: 0, y: 0 });
  const updateSpeed = () => {
    if (clicks === 20) {
      setSpeed(speed - speed / 10);
      setPerClick(perClick * 2);
      setClicks(0);
      return;
    }
    setClicks(clicks + 1);
  };
  useEffect(() => {
    if (data.multiplier !== undefined) {
      setPerClick(data.multiplier);
    }
  }, [data.multiplier]);
  const claimScore = async () => {
    if (score !== 0) {
      const scoreResult = await fetch(
        "https://server.whackhamster.com/updateScore",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ score: score, userId: data.id }),
        }
      );
      setData((prevState) => ({
        ...prevState,
        score: prevState.score + score,
      }));
      setScore(0);
      const Updatedata = await scoreResult.json();
    }
  };
  const gameOver = () => {
    setGameOver(true);
  };
  const showClickScore = (e) => {
    const { clientX, clientY } = e;
    setMultiplierPosition({ x: clientX, y: clientY });
    setShowMultiplier(true);
    setTimeout(() => setShowMultiplier(false), speed);
  };
  const [moles, setMoles] = useState(new Array(9).fill(false));
  function setMoleVisibility(index, isVisible) {
    setMoles((curMoles) => {
      const newMoles = [...curMoles];
      newMoles[index] = isVisible;
      return newMoles;
    });
  }
  function wackMole(index) {
    if (!moles[index]) {
      setScore(0);
      gameOver();
      setPerClick(data.multiplier);
      setSpeed(1000);
      setClicks(0);
      hapticFeedback.notificationOccurred("error");
      return;
    }
    setMoleVisibility(index, false);
    console.log(perClick);
    console.log(data.multiplier);
    setScore((score) => score + perClick);
    updateSpeed();
    hapticFeedback.impactOccurred("soft");
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * moles.length);
      setMoleVisibility(randomIndex, true);
      setTimeout(() => {
        setMoleVisibility(randomIndex, false);
      }, speed);
    }, speed);
    return () => {
      clearInterval(interval);
    };
  }, [moles]);

  return (
    <>
      <div className="flex justify-around mt-3 mb-5 mx-auto w-11/12 shadow-md shadow-gray-400/50">
        <h1 className="text-white text-3xl font-bold">Score {score}</h1>
        <button
          className="bg-yellow-600 text-gray-200 rounded-2xl py-2 px-4 shadow-md shadow-green-100/50 border-double border-4"
          onClick={claimScore}
        >
          Claim
        </button>
      </div>
      <div className="grid">
        {moles.map((isMole, idx) => (
          <img
            key={idx}
            alt="mole/hole"
            src={isMole ? mole : hole}
            className={isMole ? "mole" : "hole"}
            onClick={(e) => {
              wackMole(idx);
              showClickScore(e);
            }}
          />
        ))}
      </div>
      {showMultiplier && (
        <div
          className="multiplier-animation"
          style={{
            top: multiplierPosition.y - 20,
            left: multiplierPosition.x,
          }}
        >
          +{perClick}
        </div>
      )}
    </>
  );
}
export default React.memo(Game);
